body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.link-button > a {
  color: inherit;
  text-decoration: none;
 }

a:hover, a:visited, a:active, .pseudo-link:hover, .pseudo-link:visited, .pseudo-link:active {
  color: inherit;
  text-decoration: none;
}

.pseudo-link {
  text-decoration: underline;
  cursor: pointer;
}

.app-container {
  position: fixed;
  top: 0;
  bottom:0;
  width: 100%;
  height: 100%;
  z-index:10000;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #e8e1ce;
}

.text-lines-container {
  margin: 10px;
}

.main-content-container {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  height: 20px;
  width: 20px;
}

.checkbox-label {
  color: #37a0ff;
  font-size: 18px;
  padding-left: 3px;
}

.link-button {
  text-decoration: none;
  font-size: 30px;
  border: 1px solid #a8a8d7;
  padding: 10px;
  border-radius: 7px;
  margin: 15px;
}

.link-button.squat {
  width: 216px;
  text-align: center;
}

.link-button.big {
  width: 360px;
  text-align: center;
}

.link-button-small {
  text-decoration: none;
  font-size: 20px;
  border: 1px solid #a8a8d7;
  padding: 10px;
  border-radius: 7px;
  margin: 15px;
}

.year {
  font-size: 20px;
  padding-left: 5px;
}

.description {
  font-style: italic;
  padding: 10px 15px;
}

.text {
  white-space: pre-wrap;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

marquee {
  font-size: 60px;
  color: pink;
  -webkit-text-stroke-color: #ba04ba;
  -webkit-text-stroke-width: 2px;
  margin: 24px;
}

h1 {
  text-align: center;
  font-size: 36px;
}

h2 {
  text-decoration: underline;
  margin: 0 0 0 10px;
  color: #759bbd;
}

h3 {
  color: #759bbd;
  font-size: 40px;
  text-align: center;
}

.header > h3 {
  margin-bottom: 20px;
}

h4 {
  color: #37a0ff;
  margin: 0 0 0 10px;
  font-size: 25px;
  display: inline;
}

.subtitle > h5 {
  margin: 10px 0px;
  color: #282c34;
  font-size: 20px;
}

.pseudo-h4 {
  color: #37a0ff;
  margin: 0 0 0 10px;
  font-size: 25px;
  display: inline;
  font-weight: bold;
}

ul {
  padding-left: 10px;
}

ul.movement-list {
  padding-left: 25px;
}

audio {
  padding-left: 10px;
}

ul > li {
  list-style: none;
}

p {
  padding: 10px;
}

.domains-container {
  padding: 10px;
}
